<template>
  <article itemscope itemtype="https://schema.org/Thing">
    <div id="intro">
      <h2 itemprop="alternateName">Shovel - Technology Lookup Extension</h2>
      <div id="contentBlock">
        <div id="textContent" itemprop="description">
          <h3>
            Shovel enables you to check html sources if they contain words out of a
            list
          </h3>
          <p>
            Have you ever been in a situation where you wanted to identify
            technologies integrated in websites? You could check the html source
            and dig for it. But that can be tedious.
            Shovel allows you to perform
            a “multi Ctrl+F” search, for a list of words, in the html source with
            one click.
          </p>
        </div>
        <img id="picture" src="../assets/code.jpg" alt="image of code">
      </div>
    </div>
    <svg id="wave2" viewBox="0 0 1576 61" fill="none" alt="page seperator" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H1600V32.9661C834.134 68.929 774.401 71.734 0 32.9661V0Z" fill="#8C8C8C"/>
    </svg>
  </article>
</template>

<style scoped>
  #intro {
    position: relative;
    background-color: #8C8C8C;
    top: 0;
    padding-top: 45vw;
    padding-bottom: 8vw;
    margin-top: -30vw;
    width: 100%;
    z-index: 1;
  }
  #contentBlock {
    position: relative;
    padding-top: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  #textContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25%;
    color: #FFFFFF;
  }
  #picture {
    max-width: 50%;
  }
  h2 {
    color: #FFFFFF;
    padding: 0 1vh;
    margin-top: 0;
    font-size: 2em;
  }
  #wave2 {
    position: relative;
    top: -0.5em;
    width: 100%;
    left: 0;
    z-index: 1;
  }
  p {
    text-align: left;
  }
  @media all and (max-width: 800px) {
    #intro {padding-top: 59vw;}
    h2 {padding-bottom: 2vw;}
    #contentBlock {flex-direction: column-reverse;}
    #textContent {width: 80%;}
    #picture {
      height: unset;
      max-width: 90%;
    }
    #wave2 {top: -0.6em;}
  }
  @media all and (max-width: 500px) {
    h2 {padding-bottom: 5vw;}
    #textContent {width: 80%;}
  }

</style>
