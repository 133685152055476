<template>
    <article>
        <p>Shovel 2021 by Sascha Hahn</p>
        <!-- <a href="" alt="link to contacts and data protection">contact</a> -->
    </article>
</template>

<script>
export default {
}
</script>

<style scoped>
    article {
        position: relative;
        display: flex;
        top: 0;
        /* width: 100% - 2em; */
        /* height: 1em; */
        padding: 1em;
        background-color: #1A1A1A;
        /* background-color: red; */
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;
    }
    p {
        margin: 0;
        /* flex:  */
    }
    a {
        color: #FFFFFF;
        /* text-align: end; */
    }
</style>
