<template>
  <article itemscope itemtype="https://schema.org/Thing">
    <div id="inspiration">
      <div id="contentBlock">
        <img id="picture" src="../assets/bookingsystem_found.png" alt="technology found">
        <div id="textContent" itemprop="potentialAction">
          <h3>
            Inspiration
          </h3>
          <p>
            When (paid-)technology is integrated on a website, it usually leaves an at least somewhat unique identifier in the html source.
            I made shovel for example, to be able to search through the html of websites for a list of different bookingsystems.
            But you are not limited to bookingsystems, you can enter any technology you want to dig for, like on-site search engines or
            shopsystems!
          </p>
        </div>
      </div>
    </div>
    <svg id="wave3" viewBox="0 0 1576 101" fill="none" xmlns="http://www.w3.org/2000/svg" alt="page seperator">
        <path d="M0 0H1600V60.3678L1452.8 101L1329.07 27.8621L1224.53 42.954H955.733L716.8 89.3908L475.733 49.9195L262.4 77.7816L138.667 42.954L0 60.3678V0Z" fill="#717171"/>
    </svg>
  </article>
</template>

<style scoped>
  #inspiration {
    position: relative;
    background-color: #717171;
    /* top: -3vw; */
    top: 0;
    padding-top: 41vw;
    padding-bottom: 8vw;
    margin-top: -30vw;
    width: 100%;
  }
  #contentBlock {
    position: relative;
    padding-top: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  #textContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25%;
    color: #FFFFFF;
  }
  #picture {
    max-width: 50%;
  }
  h2 {
    color: #FFFFFF;
    padding: 0 1vh;
    margin-top: 0;
    font-size: 2em;
  }
  #wave3 {
    position: relative;
    top: -0.5em;
    width: 100%;
    left: 0;
  }
  p {
    text-align: left;
  }
  @media all and (max-width: 800px) {
    #inspiration {padding-top: 45vw;}
    h2 {padding-bottom: 2vw;}
    #contentBlock {flex-direction: column;}
    #textContent {width: 80%;}
    #picture {
      height: unset;
      max-width: 90%;
    }
  }
  @media all and (max-width: 600px) {
    h2 {padding-bottom: 5vw;}
    #textContent {width: 80%;}
  }

</style>
