<template>
  <div>
    <Header/>
    <Introduction/>
    <Inspiration/>
    <HowTo/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Introduction from './components/Introduction.vue'
import Inspiration from './components/Inspiration.vue'
import HowTo from './components/HowTo.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Introduction,
    Inspiration,
    HowTo,
    Footer
  }
}
</script>

<style>
  body {
    margin: 0px;
    hyphens: auto;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
