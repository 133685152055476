<template>
  <article itemscope itemtype="https://schema.org/SoftwareApplication">
    <div id="header">
      <div id="logo">
        <img id="shovel" src="../assets/shovel.svg" alt="shovel logo" />
        <h1 itemprop="name" itemscope itemtype="https://schema.org/Thing">shovel</h1>
      </div>
      <svg
        id="wave1"
        alt="page seperator"
        viewBox="0 0 1574 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 103.616L87.4444 111.018C174.889 118.419 349.778 133.221 524.667 115.967C699.556 98.5282 874.445 49.4954 1049.33 29.6047C1224.22 9.71405 1399.11 19.8907 1486.56 24.6552L1574 29.6047V0H1486.56C1399.11 0 1224.22 0 1049.33 0C874.445 0 699.556 0 524.667 0C349.778 0 174.889 0 87.4444 0H0V103.616Z"
          fill="#C4C4C4"
        />
      </svg>
    </div>
    <a itemprop="installUrl" href="https://chrome.google.com/webstore/detail/shovel/jdcndaoifepdellnbejdidhfnbnpdahe" target="_blank">
      <img id="storeLink" src="../assets/chrome_web_store.png" alt="chrome web store link">
    </a>
  </article>
</template>

<style scoped>
  #header {
    position: fixed;
    width: 100%;
    height: 2.5vw;
    left: 0;
    top: 0;
    background-color: #c4c4c4;
    z-index: 2;
  }
  h1 {
    position: fixed;
    margin: 0;
    text-align: left;
    width: 100%;
    top: -4vw;
    left: 18.5vw;
    font-family: "arial bold", sans-serif;
    letter-spacing: -0.05em;
    font-style: normal;
    font-weight: bold;
    font-size: 7vw;
    color: #8c8c8c;
    z-index: 3;
    transform: rotate(-7deg);
    overflow: hidden;
    animation-name: h1Animation;
    animation-duration: 1s;
  }

  @keyframes h1Animation {
    0% {
      transform: rotate(0deg);
      top: 1vw;
    }
    75% {
      transform: rotate(0deg);
      top: 1vw;
    }
    100% {
      transform: rotate(-7deg);
      top: -4vw;
    }
  }
  #shovel {
    position: fixed;
    top: -0.6vw;
    left: 9vw;
    padding: 1vw 3.5vw;
    height: 8.5vw;
    z-index: 3;
    animation-name: shovelAnimation;
    animation-duration: 1s;
  }

  @keyframes shovelAnimation {
    0% {
      transform: rotate(-40deg);
      left: 8.8vw;
      top: -10vw;
    }
    75% {
      transform: rotate(-40deg);
      left: 6.8vw;
      top: -2.3vw;
    }
    100% {
      transform: rotate(0deg);
      top: -0.6vw;
    }
  }

  #wave1 {
    position: absolute;
    left: 0;
    top: 2vw;
    z-index: 2;
    width: '100%';
  }

  #storeLink {
    position: fixed;
    bottom: 3em;
    right: 2em;
    max-width: 20em;
    min-width: 12em;
    width: 20vw;
    animation-name: storeOpacity;
    animation-duration: 2s;
    z-index: 5;
  }

  @keyframes storeOpacity {
  from {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

  @media all and (max-width: 800px) {
    #header {
      height: 16.5vw;
    }
    #wave1 {
      top: 16vw;
    }
    h1 {
      font-size: 16vw;
      top: -0.05em;
      left: 32vw;
      animation-name: mobileH1Animation;
    }
    @keyframes mobileH1Animation {
      0% {
        transform: rotate(0deg);
        top: 1vw;
      }
      75% {
        transform: rotate(0deg);
        top: 1vw;
      }
      100% {
        transform: rotate(-7deg);
        top: -0.05em;
      }
    }
    #shovel {
      height: 20vw;
      top: 0.2vw;
      left: 15vw;
      animation-name: mobileShovelAnimation;
    }
    @keyframes mobileShovelAnimation {
      0% {
        transform: rotate(-40deg);
        left: 12vw;
        top: -10vw;
      }
      75% {
        transform: rotate(-40deg);
        left: 11vw;
        top: -2.1vw;
      }
      100% {
        transform: rotate(0deg);
        top: 0.2vw;
      }
    }
  }
</style>
