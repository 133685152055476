<template>
  <article id="howTo" itemscope itemtype="https://schema.org/SoftwareApplication">
    <h2>How to use Shovel</h2>
    <div class="howToContent" itemprop="softwareHelp">
      <h3>Get it</h3>
      <div>
        <span>Open the Chrome Web Store </span>
        <img class="chrome" src="../assets/chrome_web_store.png" alt="chrome web store logo" />
        <span> and click </span>
        <img class="chrome" src="../assets/add_to_chrome.png" alt="add to chrome"/>
      </div>
    </div>
    <div class="howToContent">
      <h3>Pin it</h3>
      <div>
        <span>Chrome will hide new extensions by default, you will need to pin the
          extension. To do so click </span>
        <img class="icon" src="../assets/puzzle_pin.png" alt="chrome extensions puzzle piece icon"/>
        <span> on the top right of your browser and click: </span>
        <br>
        <img class="smallPic" src="../assets/pin.png" alt="pin button next to the extension"/>
      </div>
    </div>
    <div class="howToContent">
      <h3>Setup search terms</h3>
      <div>
        <span>Click on the Shovel extension in the top right of your browser. Now
          click on </span>
        <img class="icon" src="../assets/gear.png" alt="shovel gear icon"/>
        <span> in the top right.</span>
        <br>
        <img class="bigPic" src="../assets/search_words.png" alt="shovel extension options page"/>
        <br>
        <span>Here you can enter your search terms, each new line counts as a new
          word. Don't forget to click the save button.
        </span>
      </div>
    </div>
    <div class="howToContent">
      <h3>Choosing good search terms</h3>
      <div>
        <span>Open a website and navigate to a page where the technology you want
          to search for is used. Now right click the item and click:</span>
          <br>
        <img class="bigPic" src="../assets/find_search_term.png" alt="looking up good search terms in chrome devtools">
        <br>
        <span>Now search for a good identifier in source code, here “bookingkikk.net”
          would work. As it is something that would usually not appear in the source
          of a website that doesn’t use it as a technology.</span>
      </div>
    </div>
    <div class="howToContent">
      <h3>Common Mistakes</h3>
      <div>
        <span>If you use a too general term, you are going to get a lot of false positives.
          For Example: You are using the search term “<b>roller</b>” because you want to look for
          a fictitious technology from “awesome-roller-extreme.com”. Shovel is going to find
          very common words like “cont<b>roller</b>”.</span>
      </div>
    </div>
    <div class="howToContent">
      <h3>Use it in the right situation</h3>
      <div>
        <img class="bigPic" id="situation" src="../assets/bookingsystem_found.png" alt="technology found">
        <br>
        <span>Shovel is a leightweight tool and just scans the source of the currently
          open website when it is clicked. So make sure to navigate to the page that
          contains the technology you wish to investigate.</span>
      </div>
    </div>
  </article>
</template>

<style scoped>
#howTo {
  position: relative;
  /* top: -3vw; */
  top: -2em;
  padding: 8vw 0;
  /* padding-bottom: 10vw; */
  /* width: 100%; */
  background-color: #ffffff;
  z-index: -1;
}
h2 {
  padding: 0 1vw;
  padding-bottom: 3vw;
  margin-top: 0;
  font-size: 2em;
}
.howToContent {
  display: flex;
  align-items: flex-start;
  padding: 3vw 9vw;
  justify-content: flex-start;
  text-align: left;
}
h3 {
  margin-top: 0;
  margin: 0 5vw;
  font-size: 1.2em;
  white-space: nowrap;
  min-width: 15em;
}
span {
  font-size: 0.9em;
  vertical-align: top;
}
.chrome {
  height: 2.2em;
  margin-top: -0.6em;
}
.smallPic {
  width: 15em;
  padding: 1em;
}
.bigPic {
  max-height: 30em;
  max-width: 90%;
  padding: 1em;
}
.icon {
  width: 1.6em;
  margin-top: -0.3em;
}
@media all and (max-width: 1000px) {
  .howToContent {
    flex-direction: column;
    width: 80%;
  }
  h3 {
    margin: 0;
    padding-bottom: 2vw;
  }
  .smallPic {
    width: 8em;
    padding: 0;
  }
  .chrome {
    height: 1.6em;
    margin-top: -0.2em;
  }
  .icon {
    width: 1em;
    margin-top: -0.2em;
  }
}
</style>
